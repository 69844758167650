import React from 'react';

import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as TrophyIcon } from '~/assets/icons/trofeu.svg';
import { ReactComponent as OmniIcon } from '~/assets/icons/omni.svg';
import { ReactComponent as GroupIcon } from '~/assets/icons/group.svg';

import getAdoroCodigoChallengePeriod from './getAdoroCodigoChallengePeriod';

// Menus
const RANKING_VENDEDORES = 8;
const VENDEDORES = 6;
const CENTRAL_AJUDA = 20;
const SUPORTE = 21;
const SOMA_STORE = 22;
const CARRINHOS = 24;
const FAQ = 26;
const EXPERIMENTACAO = 27;
const SAIR = 28;
const CONTEUDO_POSTS_ALL = 29;
const CONTEUDO_POSTS = [30, 31, 32, 33];
const LOJINHA = 34;
const DESAFIO = 37;

// Profiles
const VENDEDORES_ID = 1;
const VENDEDOR_MM = 4;
const LOJA_MM = 7;
const VENDEDOR_AC = 14;
const GERENTE = 6;
const CAIXAS = 15;
const VENDEDORA_RESP = 18;

// Brands
const FABULA = 5;
const OUTLET = 6;
const FOXTON = 7;
const CRIS_BARROS = 9;
const MARIA_FILO = 15;
const BYNV = 16;

const SUPORT_PROFILES = [VENDEDOR_MM, LOJA_MM, VENDEDOR_AC];
const MULTIMARCA_PROFILES = [VENDEDOR_MM, LOJA_MM];
const ALLOWED_FOR_ALL = [CENTRAL_AJUDA, SUPORTE, SAIR];
const WINDOW_OPEN = [
    CENTRAL_AJUDA,
    SUPORTE,
    SOMA_STORE,
    FAQ,
    LOJINHA,
    ...CONTEUDO_POSTS,
];

const ADMINS = [
    'pedro.pedrosa@somagrupo.com.br',
    'paula.celani@somagrupo.com.br',
    'filippo.bruno@somagrupo.com.br',
    'arthur.oliveira@somagrupo.com.br',
    'kethleenborges@hotmail.com',
    'victor.carvalho@somagrupo.com.br',
    'waler.pereira@somagrupo.com.br',
    'igor.alves@somagrupo.com.br',
    'wallace.silva@somagrupo.com.br',
    'suellen.brederode@somagrupo.com.br',
    'vinicius.souza@somagrupo.com.br',
];

const MENU_ICON = {
    ExploreOutlinedIcon: <ExploreOutlinedIcon />,
    NotificationsActiveOutlinedIcon: <NotificationsActiveOutlinedIcon />,
    InstagramIcon: <InstagramIcon />,
    MonetizationOnOutlinedIcon: <MonetizationOnOutlinedIcon />,
    SchoolOutlinedIcon: <SchoolOutlinedIcon />,
    HelpOutlineOutlinedIcon: <HelpOutlineOutlinedIcon />,
    PeopleOutlineOutlinedIcon: <PeopleOutlineOutlinedIcon />,
    WhatsAppIcon: <WhatsAppIcon />,
    ExitToAppOutlinedIcon: <ExitToAppOutlinedIcon />,
    ShoppingCartOutlinedIcon: <ShoppingCartOutlinedIcon />,
    InfoOutlinedIcon: <InfoOutlinedIcon />,
    CallOutlinedIcon: <CallOutlinedIcon />,
    TrophyIcon: <SvgIcon component={TrophyIcon} />,
    OmniIcon: <SvgIcon component={OmniIcon} />,
    GroupIcon: <SvgIcon component={GroupIcon} style={{ marginTop: '6px' }} />,
};

const menuExceptions = params => {
    const { menu, user, redirect, logOut, tokenLojinha } = params;
    const { permissions } = menu;

    const phoneSuporte1 = '552137337708';
    const phoneSuporte2 = '552125036879';

    const allowedProfiles = permissions.profiles.length
        ? permissions.profiles.includes(user.id_perfil)
        : true;

    const allowedBrands = permissions.brands.length
        ? permissions.brands.includes(user.marcaFilial)
        : true;

    let allowed = allowedProfiles && allowedBrands;
    let action = () => redirect(menu.route);

    switch (menu.id) {
        case SAIR:
            action = () => logOut();
            break;

        case SUPORTE:
            menu.route = SUPORT_PROFILES.includes(user.id_perfil)
                ? `${menu.originalRoute}?phone=${phoneSuporte1}&text&app_absent=0`
                : `${menu.originalRoute}?phone=${phoneSuporte2}&text&app_absent=0`;
            break;

        case VENDEDORES:
            if (user?.marcaFilial === MARIA_FILO) {
                allowed = allowedProfiles;
            }
            break;

        case RANKING_VENDEDORES:
            const codigo_filial = user.users_branches[0]?.codigo_filial;
            const branchCode =
                codigo_filial?.length === 3
                    ? `000${codigo_filial}`
                    : codigo_filial;
            menu.route = `${menu.originalRoute}/${branchCode}`;
            break;

        case SOMA_STORE:
            menu.route = `https://somabox.somalabs.com.br/`;
            allowed = [CAIXAS, GERENTE, VENDEDORA_RESP].includes(user.perfil.id_profile) && ![BYNV].includes(params.user.marcaFilial)
            menu.text = "BOX"
            menu.name = "BOX"
            break;

        case CARRINHOS:
            allowed =
                user.users_sales_code[0] &&
                (allowedProfiles ||
                    user.marcaFilialMultimarca ||
                    user.marcaFilial);
            break;

        case EXPERIMENTACAO:
            allowed = ADMINS.includes(user.email);
            break;

        case CONTEUDO_POSTS_ALL:
            allowed =
                user?.id_perfil !== 14 &&
                ![
                    FABULA,
                    OUTLET,
                    FOXTON,
                    CRIS_BARROS,
                    BYNV,
                    MARIA_FILO,
                ].includes(user?.marcaFilial);
            break;

        case LOJINHA:
            const url = new URL(menu.route);
            url.searchParams.set('token', tokenLojinha);
            menu.route = url.toString();
            break;

        case CENTRAL_AJUDA:
            menu.route = MULTIMARCA_PROFILES.includes(user.id_perfil)
                ? 'https://sites.google.com/view/central-de-ajuda-afiliados?usp=sharing'
                : menu.originalRoute;
            break;
        case DESAFIO:
            allowed = getAdoroCodigoChallengePeriod(user.id_perfil);
            break;
        default:
            break;
    }

    if (WINDOW_OPEN.includes(menu.id)) {
        action = () => window.open(menu.route, '_blank');
    }

    if (ALLOWED_FOR_ALL.includes(menu.id)) {
        allowed = true;
    }

    return {
        allowed,
        action,
        route: menu.route,
    };
};

const generateMenu = params => {
    const { data } = params;

    if (!data) {
        return [];
    }

    const result = data.map(menu => {
        const { allowed, action, route } = menuExceptions({ menu, ...params });

        if (menu.id === 22 && params.user.perfil.id_profile === VENDEDORES_ID && params.user.marcaFilial !== BYNV) {
            return {
                name: "add_circle_outline",
                icon: "add_circle_outline",
                action: () => params.toggleState(),
                show: true,
                position: null,
                text: "BOX"
            }
        }

        return {
            name: MENU_ICON[menu.icon] || menu.icon,
            position: menu.position || null,
            text: menu.name,
            show: allowed,
            action,
            route,
        };
    });

    return result;
};

export default generateMenu;
