import 'moment/locale/pt-br';
import moment from 'moment';

export const shouldRemoveExternalProducts = userBrandId => {
    const ID_MARCA_ANIMALE = 1;
    const ID_MARCA_OFF = 6;

    const brandsToRemoveExternalProducts = [ID_MARCA_ANIMALE, ID_MARCA_OFF];
    return brandsToRemoveExternalProducts.includes(userBrandId);
};

export const getDateRangeList = (startDate, endDate) => {
    const dates = [];
    dates.push(moment(startDate).format('YYYY-MM-DD'));
    while (!moment(startDate).isSame(endDate)) {
        startDate = moment(startDate)
            .add(1, 'days')
            .format('YYYY-MM-DD');
        dates.push(moment(startDate).format('YYYY-MM-DD'));
    }

    return dates;
};

export const formattedBranchCode = branchCode => {
    return branchCode.length === 3 ? `000${branchCode}` : branchCode;
};
